:root{

  /***** TYPOGRAPHY ******/
  --bhe-font-family: 'Futura PT';
  /***** COLORS DEFINITION ******/
  --bhe-color-primary-50: 	hsl(0, 0%, 100%);
  --bhe-color-primary-100: hsl(0, 0%, 96%);
  --bhe-color-primary-200: hsl(210, 29%, 97%);
  --bhe-color-primary-300: hsl(210, 29%, 92%);
  --bhe-color-primary-400: hsl(200, 54%, 29%);
  --bhe-color-primary-400-contrast: hsl(0, 0%, 100%);
  --bhe-color-primary-700: hsl(201, 40%, 35%);
  --bhe-color-primary-700-contrast: hsl(0, 0%, 100%);
  --bhe-color-primary-800: hsl(201, 47%, 35%);
  --bhe-color-primary-800-contrast: hsl(0, 0%, 100%);
  --bhe-color-primary-900: hsl(201, 64%, 22%);
  --bhe-color-primary-900-contrast: var(--bhe-color-primary-50);
  --bhe-color-primary-1000: hsl(200, 97%, 14%);
  --bhe-color-primary-1000-contrast: var(--bhe-color-primary-50);

  --bhe-color-accent-50: 	hsl(45, 36%, 96%);
  --bhe-color-accent-300: hsl(16, 4%, 92%);
  --bhe-color-accent-400: 	hsl(16, 4%, 49%);
  --bhe-color-accent-400-contrast: 	var(--bhe-color-primary-50);
  --bhe-color-accent-500: 	hsl(16, 4%, 40%);
  --bhe-color-accent-900: hsl(43, 5%, 26%);
  --bhe-color-pdf: hsl(359, 100%, 30%);
  --bhe-color-pdf-light: hsl(359, 100%, 95%);

  --bhe-color-rejection: hsl(4, 90%, 40%);
  --bhe-color-approval: hsl(136, 90%, 40%);

  --bhe-color-electric-500: 	hsl(205, 100%, 43%);

  --bhe-color-body-bg: hsl(16, 14%, 88%);

  --bhe-color-home-tabs-bg: hsl(16, 14%, 97%);

  /***** COLORS USAGE ******/
  --bhe-color-text: var(--bhe-color-primary-900);
  --bhe-header-bg: var(--bhe-color-accent-400);
  --bhe-header-text: var(--bhe-color-accent-400-contrast);
  --bhe-bg-card-color: var(--bhe-color-primary-200);
  --bhe-border-card: var(--bhe-color-primary-300);
  --bhe-border-card-draft: var(--bhe-color-accent-300);
  --bhe-bg-color: var(--bhe-color-primary-50);
  --bhe-bg-card-draft: var(--bhe-color-accent-50);
  --bhe-text-card-draft: var(--bhe-color-accent-900);
  --bhe-reservation-header-gradient-start: var(--bhe-color-primary-900);
  --bhe-reservation-header-gradient-end: var(--bhe-color-primary-400);
  --bhe-reservation-header-text: var(--bhe-color-primary-50);
  --bhe-reservation-card-gradient-start: var(--bhe-reservation-header-gradient-start);
  --bhe-reservation-card-gradient-end: var(--bhe-reservation-header-gradient-end);
  --bhe-reservation-card-draft-gradient-start: var(--bhe-color-accent-500);
  --bhe-reservation-card-draft-gradient-end: var(--bhe-color-accent-400);
  --bhe-reservation-card-text: var(--bhe-reservation-header-text);
  --bhe-reservation-block-bg: var(--bhe-color-primary-200);
  --bhe-overview-reservation-part-header-bg: var(--bhe-color-primary-300);
  --bhe-overview-reservation-part-content-bg: var(--bhe-color-primary-200);
  --bhe-overview-reservation-part-text: var(--bhe-color-text);
  --bhe-approbation-bg: var(--bhe-color-primary-700);
  --bhe-approbation-text: var(--bhe-color-primary-50);
  --bhe-color-message-home-panel-bg: hsl(109, 29%, 96%);
  --bhe-color-message-bg: hsl(109, 29%, 92%);
  --bhe-color-message-text: hsl(109, 29%, 20%);
  --bhe-color-message-scroll-gradient-start: hsl(109, 29%, 92%);
  --bhe-color-message-scroll-gradient-end: hsla(109, 29%, 92%, 0);
  --bhe-reservation-table-text: var(--bhe-color-primary-900);
  --bhe-reservation-table-header-bg: var(--bhe-color-primary-800);
  --bhe-reservation-table-header-text: var(--bhe-color-primary-800-contrast);
  --bhe-reservation-table-row-odd-bg: var(--bhe-color-primary-50);
  --bhe-reservation-table-row-even-bg: var(--bhe-color-primary-200);
  --bhe-reservation-table-filter-bar-bg: var(--bhe-color-primary-900);
  --bhe-autocomplete-option-bg: var(--bhe-color-primary-300);
  --bhe-autocomplete-option-hover-bg: var(--bhe-color-primary-200);
  /****** STATUS COLORS ******/
  --bhe-status-text-light: hsl(0, 0%, 100%);
  --bhe-status-text-dark: hsl(200, 54%, 29%);
  --bhe-status-draft: #827A77;
  --bhe-status-ready: #5CC516;
  --bhe-status-submitted: #EFC10D;
  --bhe-status-approved: #F3850D;
  --bhe-status-confirmed: #0FB6F3;
  --bhe-status-wns: #E31A62;
  --bhe-status-closed: var(--bhe-color-primary-50);
  --bhe-status-cancelled: var(--bhe-color-primary-50);
  --bhe-status-local: #827A77;
  /***** SPACINGS ******/
  // https://medium.com/codyhouse/create-your-design-system-part-4-spacing-895c9213e2b9
  /* spacing values */
  --space-unit: 1em;
  --space-xxxs: calc(0.25 * var(--space-unit));
  --space-xxs:  calc(0.375 * var(--space-unit));
  --space-xs:   calc(0.5 * var(--space-unit));
  --space-sm:   calc(0.75 * var(--space-unit));
  --space-md:   calc(1.25 * var(--space-unit));
  --space-lg:   calc(2 * var(--space-unit));
  --space-xl:   calc(3.25 * var(--space-unit));
  --space-xxl:  calc(5.25 * var(--space-unit));
  --space-xxxl: calc(8.5 * var(--space-unit));
  /***** SIZES ******/
  --bhe-header-height: 50px;
  --bhe-reservation-mini-list-width: 210px;
  --bhe-reservation-header-height: 140px;
  --bhe-status-icon-size: 40px;
  --bhe-status-icon-size-sm: 24px;
  --bhe-status-icon-size-xs: 16px;
  --bhe-status-icon-size-md: 50px;
  --bhe-size-button-default: 36px;
  --bhe-size-button-lg: 50px;
  --bhe-size-button-sm: 24px;
  --bhe-size-main-max-width: 1400px;
  --bhe-size-mat-tab-height: 48px;
  --bhe-size-reservation-content-sticky-top: calc(var(--bhe-header-height) + var(--bhe-size-mat-tab-height));
  /****** TYPOGRAPHY ******/
  /* SIZE */
  --bhe-typo-size-xxxxxxl: 8em;
  --bhe-typo-size-xxxxxl: 4em;
  --bhe-typo-size-xxxxl: 3.2143em;
  --bhe-typo-size-xxxl: 2.4286em;
  --bhe-typo-size-xxl: 1.7143em;
  --bhe-typo-size-xl: 1.4286em;
  --bhe-typo-size-lg: 1.1429em;
  --bhe-typo-size-md: 1.0714em;
  --bhe-typo-size: 1em;
  --bhe-typo-size-xs: 0.8571em;
  --bhe-typo-size-xxs: 0.78em;
  --bhe-typo-size-xxxs: 0.62em;
  /* LEADING */
  --bhe-typo-leading-xxxxxxl: 8em;
  --bhe-typo-leading-xxxxxl: 4em;
  --bhe-typo-leading-xxxxl: 3.4286em;
  --bhe-typo-leading-xxxl: 2.8571em;
  --bhe-typo-leading-xxl: 2.2857em;
  --bhe-typo-leading-xl: 2.2857em;
  --bhe-typo-leading-lg: 2em;
  --bhe-typo-leading-md: 1.7143em;
  --bhe-typo-leading: 1.4286em;
  --bhe-typo-leading-xs: 1em;
}
