@use "reset";
@use "variables" as variables;
@use "material" as material;
@use '@angular/material' as mat;
@use 'list' as list;
@use 'link' as link;
@use 'window' as window;
@use 'functional' as functional;
@use 'forms' as forms;

html{
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

body{

  display: flex;
  flex-direction: column;

  min-height: 100%;
  font-family: var(--bhe-font-family);
  color: var(--bhe-color-text);
  // user-select: none;
  background-color: var(--bhe-color-body-bg);

  a{
    @include link.reset_link();
  }

}

router-outlet{
  display: contents;
}
