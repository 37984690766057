@mixin reservation-parts-accordion() {

  mat-expansion-panel {
    box-shadow: none !important;
    border-radius: 0 !important;
    margin-bottom: 2px !important;

    &.mat-expanded {
      overflow: visible !important;
    }

    mat-expansion-panel-header {
      background-color: var(--bhe-overview-reservation-part-header-bg) !important;

      mat-chip {

        pointer-events: none;

        font-size: 12px !important;
        min-height: 24px !important;
        border-radius: 12px !important;
        padding: 7px 11px !important;

        background-color: color-mix(in srgb, var(--bhe-color-primary-700), transparent 85%) !important;
        //color: white!important;

        display: flex;
        gap: var(--space-xs);

        mat-icon {
          height: 14px !important;
          width: 14px !important;
        }
      }

    }

    .mat-expansion-panel-content {
      background-color: var(--bhe-overview-reservation-part-content-bg);
    }

  }

  .mat-expansion-panel, .mat-expansion-panel-header-title {
    color: var(--bhe-overview-reservation-part-text) !important;
  }

}


mat-option{

  color: var(--bhe-color-text);

  &.mat-active{
    background-color: var(--bhe-autocomplete-option-bg);
  }

  &:hover{
    background-color: var(--bhe-autocomplete-option-hover-bg);
  }

}


.process-saving{
  cursor: wait !important;
}
