@use "bhe-styles";
@use "flag-icons";

@font-face {
  font-family: 'Futura PT';
  font-display: block;
  src: url('assets/fonts/FuturaPT-Medium.eot');
  src: url('assets/fonts/FuturaPT-Medium.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/FuturaPT-Medium.woff2') format('woff2'),
  url('assets/fonts/FuturaPT-Medium.woff') format('woff'),
  url('assets/fonts/FuturaPT-Medium.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Futura PT';
  font-display: block;
  src: url('assets/fonts/FuturaPT-Book.eot');
  src: url('assets/fonts/FuturaPT-Book.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/FuturaPT-Book.woff2') format('woff2'),
  url('assets/fonts/FuturaPT-Book.woff') format('woff'),
  url('assets/fonts/FuturaPT-Book.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

