.cdk-overlay-pane {

  mat-dialog-container {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 0;
    color: var(--bhe-color-text);
  }

  &.content {
    mat-dialog-container {
      padding: 0;
    }
  }

  &.alert {

    overflow: visible;

    mat-dialog-container {
      overflow: visible;
      width: 80vw;
      max-width: 400px;
      padding: 0;
    }
  }

}

mil-config-switcher {
  z-index: 10000000;
}
