@use '@angular/material' as mat;
@use 'sass:map';

@include mat.core();

$primary-palette: (
  50: var(--bhe-color-primary-700),
  100: var(--bhe-color-primary-700),
  200: var(--bhe-color-primary-700),
  300: var(--bhe-color-primary-700),
  400: var(--bhe-color-primary-700),
  500: var(--bhe-color-primary-700),
  600: var(--bhe-color-primary-700),
  700: var(--bhe-color-primary-700),
  800: var(--bhe-color-primary-700),
  900: var(--bhe-color-primary-700),
  A100: var(--bhe-color-primary-700),
  A200: var(--bhe-color-primary-700),
  A400: var(--bhe-color-primary-700),
  A700: var(--bhe-color-primary-700),
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white,
  )
);

$accent-palette: (
  50: var(--bhe-color-electric-500),
  100: var(--bhe-color-electric-500),
  200: var(--bhe-color-electric-500),
  300: var(--bhe-color-electric-500),
  400: var(--bhe-color-electric-500),
  500: var(--bhe-color-electric-500),
  600: var(--bhe-color-electric-500),
  700: var(--bhe-color-electric-500),
  800: var(--bhe-color-electric-500),
  900: var(--bhe-color-electric-500),
  A100: var(--bhe-color-electric-500),
  A200: var(--bhe-color-electric-500),
  A400: var(--bhe-color-electric-500),
  A700: var(--bhe-color-electric-500),
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white,
  )
);

$bhe-primary: mat.define-palette($primary-palette, 700);
$bhe-accent: mat.define-palette($accent-palette, 500);

$bhe-typography: mat.define-typography-config(
  // Specify "Comic Sans" as the default font family for all levels.
  $font-family: var(--bhe-font-family),
  $display-4: mat.define-typography-level(var(--bhe-typo-size-xxxxxxl), var(--bhe-typo-leading-xxxxxxl), 300, $letter-spacing: -0.05em),
  $display-3: mat.define-typography-level(var(--bhe-typo-size-xxxxxl), var(--bhe-typo-leading-xxxxxl), 400, $letter-spacing: -0.02em),
  $display-2: mat.define-typography-level(var(--bhe-typo-size-xxxxl), var(--bhe-typo-leading-xxxxl), 400, $letter-spacing: -0.005em),
  $display-1: mat.define-typography-level(var(--bhe-typo-size-xxxl), var(--bhe-typo-leading-xxxl), 400),
  $headline: mat.define-typography-level(var(--bhe-typo-size-xxl), var(--bhe-typo-leading-xxl), 400),
  $title: mat.define-typography-level(var(--bhe-typo-size-xl), var(--bhe-typo-leading-xl), 500),
  $subheading-2: mat.define-typography-level(var(--bhe-typo-size-lg), var(--bhe-typo-leading-lg), 400),
  $subheading-1: mat.define-typography-level(var(--bhe-typo-size-md), var(--bhe-typo-leading-md), 400),
  $body-2: mat.define-typography-level(var(--bhe-typo-size), var(--bhe-typo-leading-md), 500),
  $body-1: mat.define-typography-level(var(--bhe-typo-size), var(--bhe-typo-leading), 400),
  $caption: mat.define-typography-level(var(--bhe-typo-size-xs), var(--bhe-typo-leading), 400),
  $button: mat.define-typography-level(var(--bhe-typo-size), var(--bhe-typo-leading-xs), 500),
    // Line-height must be unit-less fraction of the font-size.
  $input: mat.define-typography-level(inherit, 1.125, 400)
);

$bhe-theme: mat.define-light-theme((
  color: (
    primary: $bhe-primary,
    accent: $bhe-accent,
  ),
  typography: $bhe-typography
));

@include mat.table-theme($bhe-theme);
@include mat.form-field-theme($bhe-theme);
@include mat.input-theme($bhe-theme);
@include mat.progress-spinner-theme($bhe-theme);
@include mat.tabs-theme($bhe-theme);
@include mat.expansion-theme($bhe-theme);
@include mat.button-theme($bhe-theme);
@include mat.autocomplete-theme($bhe-theme);
@include mat.radio-theme($bhe-theme);
@include mat.select-theme($bhe-theme);
@include mat.icon-theme($bhe-theme);
@include mat.badge-theme($bhe-theme);
@include mat.dialog-theme($bhe-theme);
@include mat.stepper-theme($bhe-theme);
@include mat.chips-theme($bhe-theme);
@include mat.menu-theme($bhe-theme);
@include mat.list-theme($bhe-theme);
@include mat.button-toggle-theme($bhe-theme);
@include mat.divider-theme($bhe-theme);
@include mat.datepicker-theme($bhe-theme);
@include mat.checkbox-theme($bhe-theme);
@include mat.option-theme($bhe-theme);

$bhe-dark-theme: mat.define-dark-theme((
  color: (
    primary: $bhe-primary,
    accent: $bhe-accent,
  ),
  typography: $bhe-typography
));

.dark-context {
  @include mat.form-field-theme($bhe-dark-theme);
  @include mat.select-theme($bhe-dark-theme);
  @include mat.datepicker-theme($bhe-dark-theme);
}

.mat-list-item, .mat-list-option {
  font-size: var(--bhe-typo-size) !important;
}

.mat-form-field {

  &.mat-form-field-should-float {
    .mat-form-field-label {
      color: var(--bhe-color-electric-500) !important;
    }
  }

  &.mat-focused {
    .mat-form-field-label {
      color: var(--bhe-color-electric-500) !important;
    }

    .mat-form-field-underline:before {
      background-color: var(--bhe-color-electric-500) !important;
    }
  }

  .mat-form-field-ripple {
    background-color: var(--bhe-color-electric-500) !important;
  }

  .mat-form-field-flex {
    border-radius: 0 !important;
  }
}

.mat-option{
  color: var(--bhe-color-primary-900);

  &.mat-active{
    color: var(--bhe-color-primary-900);
  }
}

*[readonly=readonly], *[readonly=true]{
  opacity: 0.75;
  pointer-events: none;
}

